import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';

import { useNotification } from '@/modules/core/compositions/notification';

export const useUpdatePurchaseListItem = (errorCallback) => {
  const { error } = useNotification();

  const { mutate, loading, onError, onDone } = useMutation(UPDATE_PURCHASE_LIST_ITEM);

  onError((err) => {
    console.error('useUpdatePurchaseListItem', err);
    error();
    if (errorCallback) errorCallback();
  });

  return {
    updatePurchaseListItem: mutate,
    loading,
    onDone,
  };
};

const UPDATE_PURCHASE_LIST_ITEM = gql`
  mutation updatePurchaseListItem($businessId: ID!, $supplierId: ID!, $productId: ID!, $isRecommended: Boolean) {
    updatePurchaseListItem(
      businessId: $businessId
      productId: $productId
      supplierId: $supplierId
      isRecommended: $isRecommended
    ) {
      success
    }
  }
`;
